<template>
  <div class="Btra">
    <v-row no-gutters>
      <!-- Botonera -->
      <template v-for="(elem, idx) in btra_particular">
        <template v-if="typeof elem === 'number'">
          <v-btn
            v-if="elem && design"
            :style="elem.styB? elem.styB : 'margin-left:5px'"
            :class="design.class_btn"
            :key="idx"
            v-bind="design"
            :title="$cfg.btn[design.btns[idx]].tooltip || ''"
            @click="event_btra(idx)">

              <!-- Label -->
              <template v-if="design.label">
                <span :class="design.class_label">{{ $cfg.btn[design.btns[idx]].label }}</span>
              </template>

              <!-- Icono -->
              <v-icon v-bind="design.icons">
                {{ $cfg.btn[design.btns[idx]].icon }}
              </v-icon>
          </v-btn>

          <!-- <v-btn v-else :key="idx" icon disabled></v-btn>    -->
        </template>

        <template v-else>
          <v-btn
            v-if="elem.show"
            :style="elem.styB ? elem.styB : 'margin-left:5px'"
            :class="design.class_btn"
            :key="idx"
            v-bind="design"
            :title="$cfg.btn[elem.btn].tooltip || ''"
            @click="event_btra(elem.accion)">

              <!-- Label -->
              <template v-if="design['label']">
                <span :class="design.class_label">{{ $cfg.btn[elem.btn].label }}</span>
              </template>

              <!-- Icono -->
              <v-icon v-bind="design.icons">
                {{ $cfg.btn[elem.btn].icon }}
              </v-icon>
          </v-btn>
        </template>
      </template>
    </v-row>
  </div>
</template>

<script>

  export default {
    name: "btra",
    props: {
      btra_particular: { type: [Array, Object], default: null },
      btra_modulo: { type: String, default: "" }
    },

    data() {
      return {
        design: this.$cfg.btra_cfg[this.btra_modulo]
      };
    },

    methods: {
      // gestor de eventos
      event_btra(accion) {
        console.log("*** event_btra. Emit btra: " + accion + " ***");
        this.$emit("onEvent", { accion:accion });
      }
    }
  };
</script>
